<template>
  <!-- 旅游攻略 -->
  <div class="ly_strategy">
    <div
      class="ly_strategy_bg centerText"
      :style="{
        background: `url(${glData.basicBg}) 100%/100% no-repeat`
      }"
    ></div>
    <div class="ly_strategy_img" v-if="glData">
      <el-image
        v-for="(item, idx) in glData.basicImg"
        :key="idx"
        :src="item"
        :preview-src-list="glData.basicImg"
      >
      </el-image>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      glData: {}
    }
  },
  methods: {
    getData () {
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.glData = res.data.data.basic
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.ly_strategy {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  .ly_strategy_bg {
    width: 100%;
    height: 30vw;
  }
  .ly_strategy_img {
    padding: 3vw 0;
    width: calc(100% - 20vw);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -2vw;
    /deep/img {
      width: auto;
      height: auto;
      max-width: 100%;
      margin-top: 2vw;
    }
  }
}
</style>
